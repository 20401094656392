var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "160",
        height: "22",
        viewBox: "0 0 160 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M21.6606 0.0251465H0V4.28917H21.2284V8.60785H12.7479V12.8959H21.6606C23.7488 12.8959 25.4417 11.1826 25.4417 9.06919V3.85183C25.4417 1.73841 23.7488 0.0251465 21.6606 0.0251465Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.48057 8.60785H4.59139V21.5092H8.48057V8.60785Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.59139 8.60785H0V12.8959H4.5914L4.59139 8.60785Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.7842 21.6601C32.4101 21.6601 31.1684 21.3638 30.059 20.7713C28.9622 20.1788 28.0987 19.3657 27.4683 18.332C26.8506 17.2856 26.5417 16.1132 26.5417 14.8147C26.5417 13.5163 26.8506 12.3501 27.4683 11.3164C28.0987 10.2701 28.9622 9.45063 30.059 8.85812C31.1684 8.26561 32.4101 7.96936 33.7842 7.96936C35.1583 7.96936 36.3938 8.26561 37.4906 8.85812C38.5873 9.45063 39.4509 10.2701 40.0812 11.3164C40.7115 12.3501 41.0267 13.5163 41.0267 14.8147C41.0267 16.1132 40.7115 17.2856 40.0812 18.332C39.4509 19.3657 38.5873 20.1788 37.4906 20.7713C36.3938 21.3638 35.1583 21.6601 33.7842 21.6601ZM33.7842 19.0505C34.5658 19.0505 35.2718 18.8741 35.9021 18.5211C36.5325 18.1555 37.0241 17.6512 37.3771 17.0083C37.7427 16.3653 37.9255 15.6342 37.9255 14.8147C37.9255 13.9953 37.7427 13.2641 37.3771 12.6212C37.0241 11.9783 36.5325 11.4803 35.9021 11.1273C35.2718 10.7617 34.5658 10.5789 33.7842 10.5789C33.0026 10.5789 32.2967 10.7617 31.6663 11.1273C31.036 11.4803 30.538 11.9783 30.1724 12.6212C29.8195 13.2641 29.643 13.9953 29.643 14.8147C29.643 15.6342 29.8195 16.3653 30.1724 17.0083C30.538 17.6512 31.036 18.1555 31.6663 18.5211C32.2967 18.8741 33.0026 19.0505 33.7842 19.0505Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M51.7628 21.4332L49.21 17.7458H49.0587H46.3924V21.4332H43.329V8.19628H49.0587C50.2311 8.19628 51.2459 8.39168 52.1032 8.78248C52.973 9.17329 53.6412 9.72798 54.1076 10.4466C54.5741 11.1651 54.8073 12.0161 54.8073 12.9994C54.8073 13.9827 54.5677 14.8336 54.0887 15.5522C53.6223 16.2582 52.9541 16.8003 52.0843 17.1785L55.0531 21.4332H51.7628ZM51.7061 12.9994C51.7061 12.2556 51.4665 11.6883 50.9875 11.2975C50.5084 10.8941 49.8088 10.6924 48.8885 10.6924H46.3924V15.3064H48.8885C49.8088 15.3064 50.5084 15.1047 50.9875 14.7013C51.4665 14.2979 51.7061 13.7306 51.7061 12.9994Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M59.7916 10.6924H55.5558V8.19628H67.0909V10.6924H62.855V21.4332H59.7916V10.6924Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M78.4648 10.6923H74.229V8.19617H85.764V10.6923H81.5282V21.4331H78.4648V10.6923Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M95.8461 21.4331L93.2933 17.7457H93.142H90.4757V21.4331H87.4123V8.19617H93.142C94.3144 8.19617 95.3292 8.39157 96.1865 8.78237C97.0563 9.17318 97.7245 9.72787 98.1909 10.4464C98.6574 11.165 98.8906 12.016 98.8906 12.9993C98.8906 13.9826 98.651 14.8335 98.172 15.5521C97.7056 16.2581 97.0374 16.8002 96.1676 17.1784L99.1364 21.4331H95.8461ZM95.7894 12.9993C95.7894 12.2555 95.5498 11.6882 95.0708 11.2974C94.5917 10.894 93.8921 10.6923 92.9718 10.6923H90.4757V15.3063H92.9718C93.8921 15.3063 94.5917 15.1046 95.0708 14.7012C95.5498 14.2978 95.7894 13.7305 95.7894 12.9993Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M110.035 18.5966H103.889L102.717 21.4331H99.578L105.478 8.19617H108.503L114.422 21.4331H111.208L110.035 18.5966ZM109.071 16.2707L106.972 11.2028L104.873 16.2707H109.071Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M127.936 8.19617V21.4331H125.421L118.821 13.3964V21.4331H115.796V8.19617H118.329L124.91 16.2329V8.19617H127.936Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M141.789 18.937V21.4331H130.217V19.4475L137.478 10.6923H130.368V8.19617H141.506V10.1817L134.263 18.937H141.789Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M143.754 8.19617H146.817V21.4331H143.754V8.19617Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M152.701 10.6923H148.465V8.19617H160V10.6923H155.764V21.4331H152.701V10.6923Z",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }